<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            itemText="codeName"
            codeGroupCd="EDU_CLASS_CD"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정교육 여부 -->
          <c-select
            :editable="editable"
            label="법정교육 여부"
            itemText="codeName"
            itemValue="code"
            type="search"
            :comboItems="flagItems"
            name="legalEducationFlag"
            v-model="searchParam.legalEducationFlag">
          </c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="교육과정 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="eduCourseId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-curriculum",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            align: "left",
            style: 'width:200px',
            sortable: true,
            type: "link",
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "legalEducationFlagName",
            field: "legalEducationFlagName",
            label: "법정교육여부",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTimeName",
            field: "educationTimeName",
            label: "교육시간",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "estimatedEducationExpensesName",
            field: "estimatedEducationExpensesName",
            label: "예상교육비",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            label: "학습목적",
            style: 'width:350px',
            align: "left",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            label: "사용여부",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        useFlag: 'Y',
        legalEducationFlag: null,
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      flagItems: [
        { code: null, codeName: '전체' },
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      // code setting
      this.listUrl = selectConfig.sop.edu.course.list.url;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "교육 과정 상세"; // 교육 과정 상세
      this.popupOptions.param = {
        eduCourseId: row.eduCourseId ? row.eduCourseId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./cc/educationCurriculumDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./cc/educationCurriculumDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '교육 과정 상세'; // 교육 과정 상세
      this.popupOptions.param = {
        eduCourseId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  },
};
</script>
